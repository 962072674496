import { Link } from 'gatsby'
import React from 'react'
import "../../../styles/AdminPage.scss";
import Navbar from '../../../sections/Navbar/Navbar';
import Footer from '../../../sections/Footer';

function AdminPage() {
    return (
        <>
            <Navbar />
            <div className="AdminHome">
                <span>
                    <b>L'application #Coachello</b> permet aux leaders émergents de trouver leurs opportunités de croissance et d'y travailler par le biais d'un coaching 1:1. Il s'agit d'un développement personnel 100 % personnalisé, intégré au lieu de travail.
                </span>
                <ul className="link-list">
                    <b>
                        <li><Link to="coachello-to-colleagues">• Comment rendre Coachello accessible à ses collègues</Link></li>
                        <li><Link to="faq-ms-teams">• FAQs</Link></li>
                        <li><Link to="how-the-teamsapp-works">• Comment fonctionne l'application Teams</Link></li>
                        <li><Link to="implementation-for-users">• Déploiement pour les utilisateurs </Link></li>
                        <li><Link to="microsoft-365-certification">• Certification Microsoft 365 </Link></li>
                        <li><Link to="permissions-&-scope">• Autorisation et champ d'application</Link></li>
                    </b>
                </ul>
            </div>
            <Footer />
        </>
    )
}

export default AdminPage